@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

#launchgirl {
    position: relative;
    box-shadow: 0px -10px 50px rgba($purple, 0.2);
    padding: 3rem 1rem;
    .lglogo { //logo
        display: block;
        margin-bottom: 1.5rem;
        width: 18rem; 
    }
    .tagline-taco {
        display: none;
    }
    .taco {
        .tagline-cat {
            display: none;
        }
        .tagline-taco {
            display: block;
        }
    }
    h3 {
        color: $maroon;
    }
    .h6 {
        color: $maroon;
        &:hover {
            color: $red-700;
            text-decoration: underline;
        }
    }
    small {
        color: $red-600;
    }
    img.tacocat {
        width: 450px;
        max-width: 100%;
    }
}
@include color-mode(dark) {
    #launchgirl {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        h3 {
            color: $lime;
        }
        .h6 {
            color: $lime;
            &:hover {
                color: $teal;
                text-decoration: underline;
            }
        }
        small {
            color: $blue-400;
        }
    }
}
@include media-breakpoint-down(md) {
    #launchgirl {
        .lglogo {
            width: 15rem;
        }
    }
}
@include media-breakpoint-down(lg) {
    #launchgirl {
        background: url(../../public/img/backgrounds/stars-lt.png) no-repeat bottom center;
    }
}
@include color-mode(dark) {
    @include media-breakpoint-down(lg) {
        #launchgirl {
            background: url(../../public/img/backgrounds/stars-dk.png) no-repeat bottom center;
        }
    }
}

.transition-500 {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

#mywork {
    position: relative;
    box-shadow: 0px -10px 50px rgba($danger, 0.2);
    padding: 4rem 1rem 7rem 1rem;
    background: $coral url(../../public/img/backgrounds/topographic.png) repeat;
    h2 { color: $white; }
}

@include media-breakpoint-down(sm) {
    #mywork {
        padding-bottom: 3rem;
    }
}
@include color-mode(dark) {
    #mywork {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        background: transparent;
        h2 { color: $lime; }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: $body-secondary-bg-dark url(../../public/img/backgrounds/topographic.png) repeat;
            opacity: .35;
            z-index: -1;
        }
    }
}

#clients {
    border-top: 1px solid rgba($gray-500, .25);
    border-bottom: 1px solid rgba($gray-500, .5);
    position: relative;
    box-shadow: 0px -10px 50px rgba($maroon, 0.5);
    .brands { 
        color: $danger; 
    }
}
@include color-mode(dark) {
    #clients {
        border-top: 1px solid rgba($primary, .5);
        border-bottom: 1px solid rgba($primary, .25);
        box-shadow: 0px 0px 50px rgba($black, 0.5);
        .brands { 
            color: $blue-300; 
        }
    }
}

#archive {
    position: relative;
    box-shadow: 0px 0px 50px inset rgba($orange-600, 0.5);
    padding: 0 1rem;
    min-height: 480px;
    background: rgba($white, .8);
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #ecebe6 url(../../public/img/backgrounds/portfolio-at-a-glance-h.jpg) top left repeat;
        background-attachment: fixed;
        z-index: -1;
    }    
    &:after {
        content: "";
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(../../public/img/home/working.png) bottom right no-repeat;
        background-size: auto 100%;
    }
    .colB {
        background-color: rgba($white, 0.7);
        padding: 3rem 2.5rem;
        border-left: 1px solid $white;
        border-right: 1px solid $white;
    }
    h2 em {
        color: $orange;
        margin-right: .25rem;
    }
    .pbar {
        padding-right: .5rem;
        div {
            width: 0;
            height: 1.5rem;
            border-radius: .5rem;
        }
    }
    .pbar1 {
        max-width: 27%;
        flex-basis: 27%;
        div {
            background-color: $warning;
        }
    }      
    .pbar2 {
        max-width: 38%;
        flex-basis: 38%;
        div {
            background-color: $orange;
        }
    }      
    .pbar3 {
        max-width: 15%;
        flex-basis: 15%;
        div {
            background-color: $danger;
        }
    }   
    .pbar4 {
        max-width: 20%;
        flex-basis: 20%;
        div {
            background-color: $maroon;
        }
    }
    .pbar5 {
        max-width: 3%;
        flex-basis: 3%;
        div {
            background-color: $success;
        }
    }
    .plabel {        
        strong {
            color: $maroon;
        }
    }
    .pblurb {
        font-size: .875rem;
        line-height: 1.3;
        color: $red-700;
    }
}
@include color-mode(dark) {
    #archive {
        box-shadow: 0px 10px 50px inset rgba($black, 0.2);
        // background-color: $body-tertiary-bg-dark;   
        background: rgba($body-tertiary-bg-dark, .9);  
        .colB {
            background-color: rgba($dark, 0.7);
            padding: 3rem 2.5rem;
            border-left: 1px solid $dark;
            border-right: 1px solid $dark;
        }
        h2 {
            color: $teal;
            em {
                color: $chartreuse;
            }     
        }
        .pbar1 {
            div {
                background-color: $info;
            }
        }      
        .pbar2 {
            div {
                background-color: $lime;
            }
        }      
        .pbar3 {
            div {
                background-color: $green;
            }
        }   
        .pbar4 {
            div {
                background-color: $success;
            }
        } 
        .pbar5 {
            div {
                background-color: $purple;
            }
        }
        .plabel {        
            strong {
                color: $cyan-400;
            }
        }
        .pblurb {
            color: $gray-400;
        }
    }
}
@include media-breakpoint-between(md,lg) {
    #archive {
        &:after {
            width: 60%;
            z-index: 0;
        }
        .colB {
            z-index: 1;
        }
    }
}
@include media-breakpoint-down(md) {
    #archive {
        &:after {
            width: 100%;
            background-size: auto 25rem;
            background-position: bottom center;
        }
        .colB {
            padding-bottom: 24rem;
        }
    }
    @include color-mode(dark) {
        #archive {
            .colB {
                padding-bottom: 24rem;
            }
        }
    }
}

.popover {
    border: 2px solid $red-800;
    box-shadow: 0px 0px 10px rgba($red-800, 0.35);
}
.bs-popover-top > .popover-arrow::after, 
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
    border-top-color: $red-800;
}
.popover-header {
    color: $white;
    background-color: $red-800;
    border-bottom: 0;
}
@include color-mode(dark) {
    .popover {
        border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    }
    .bs-popover-top > .popover-arrow::after, 
    .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
        border-top-color: var(--bs-popover-bg);
    }
    .popover-header {
        color: var(--bs-popover-header-color);
        background-color: var(--bs-popover-header-bg);
        border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    }
    .popover-body {
        color: var(--bs-popover-body-color);
    }
}


#showcase {
    position: relative;
    box-shadow: 0px -10px 50px rgba($danger, 0.2);
    background-color: $red-900;
}
@include color-mode(dark) {
    #showcase {
        box-shadow: 0px -10px 50px rgba($purple, 0.2);
        background-color: transparent;
    }
}

#tools {
    border-top: 1px solid rgba($gray-500, .25);
    border-bottom: 1px solid rgba($gray-500, .5);
    position: relative;
    .brands { 
        color: $danger; 
    }
}
#tools-floating {border: 1px solid yellow;
    height: 300px;
}
@include color-mode(dark) {
    #tools {
        border-top: 1px solid rgba($primary, .5);
        border-bottom: 1px solid rgba($primary, .25);
        .brands { 
            color: $blue-300; 
        }
    }
}