@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

#intro {
    position: relative;
    box-shadow: 0px -10px 50px rgba($purple, 0.2);
    padding: 3rem 1rem;
    
    .team-content-area:hover .member-thumb img, .team-content-area:focus .member-thumb img {
        -webkit-transform: rotate(25deg);
        -ms-transform: rotate(25deg);
        transform: rotate(25deg); 
    }
    h3 { color: $maroon; }
    small { color: $red-600; }
    .smaller { 
        font-size: .625rem;
        color: $gray-400;
    }
    .card { 
        position: relative;
        overflow: hidden;
        max-width: 16rem;
        border: 1px solid $white;
        border-top-color: rgba($gray-300, .5);
        border-left-color: rgba($gray-300, .5);
        .card-body {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                height: 150%;
                background: var(--bs-body-bg) url(../../public/img/backgrounds/triangles3.jpg) center bottom repeat-x;
                z-index: -1;
                -webkit-transform: scale(-1);
                transform: scale(-1);
            }
            img { background-color: $red-800; }
        }
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: url(../../public/img/backgrounds/triangles3.jpg) left bottom repeat-x;
        z-index: -1;
    }
}
@include color-mode(dark) {
    #intro {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        h3 { color: $lime; }
        small { color: $blue-400; }
        .card {
            border: 1px solid $gray-700;
            border-top-color: rgba($gray-600, .25);
            border-left-color: rgba($gray-600, .25);                
            .card-body {
                &:before {
                    background: $dark url(../../public/img/backgrounds/triangles4.png) center bottom repeat-x;
                }
                img { background-color: $dark; }
            }        
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background: url(../../public/img/backgrounds/triangles4.png) left bottom repeat-x;
            z-index: -1;
        }
    }
}


#appetizers {
    position: relative;
    box-shadow: 0px -10px 50px rgba($danger, 0.2);
    padding: 4rem 1rem 3rem 1rem;
    background: #d4cfc5 url(../../public/img/backgrounds/topographic.png) repeat;
    h2 { color: $maroon; }
    .carousel {
        overflow: hidden; 
        padding-bottom: 4rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 960px;
    }
    .carousel-caption { 
        top: 100%; 
        right: 0;
        left: 0;
        color: var(--bs-body-color);
        font-size: .875rem;
        line-height: 1.1;
    }
    .carousel-inner { overflow: visible; }
    .carousel-control-next, .carousel-control-prev, .carousel-indicators { bottom: 4rem; }
    .carousel-control-next, .carousel-control-prev {
        > span { 
            border: 5px solid $black;
            background-color: $black;
            border-radius: 50%;
            padding: 5px;
        }
    }
    .carousel-indicators {
        bottom: -10rem;
    }
}
@include media-breakpoint-down(sm) {
    #appetizers {
        padding-bottom: 3rem;
    }
}
@include color-mode(dark) {
    #appetizers {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        background: transparent;
        h2 { color: $lime; }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: $body-secondary-bg-dark url(../../public/img/backgrounds/topographic.png) repeat;
            opacity: .35;
            z-index: -1;
        }
    }
}

#maincourse {
    .gradient-bg {
        padding: 2rem 1rem;
        h2 { color: $white; }
        h3 { color: $yellow-100; }
    }
    .slides-bg { background-color: $red-800; }
    .intro {
        max-width: 750px;
        margin: 0 auto 1rem auto;
    }
}
@include color-mode(dark) {
    #maincourse {
        .gradient-bg {
            padding: 2rem 1rem;
            h2 { color: $lime; }
            h3 { color: $white; }
        }
        .slides-bg { background-color: $gray-800; }
    }
}

#subnav {
    z-index: 3;
    position: relative;
    box-shadow: 0px -10px 50px rgba($red-800, 0.2);
    padding: 0;
    background-color: $coral;
    .h5 { 
        position: relative;
        color: $white; 
    }
    .row > div { 
        padding: 1.5rem 0;
        &:hover { 
            background-color: $danger; 
            cursor: pointer;
            .h5 {
                &.r2d {
                    i { display: inline-block; transform: rotate(90deg); }
                }
                &.r2u {
                    i { display: inline-block; transform: rotate(-45deg); }
                }
            }
        }    
    }

}
@include color-mode(dark) {
    #subnav {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        background-color: $blue-800;
        .h5 { color: $teal; } 
        .row > div { 
            &:hover { 
                background-color: shade-color($blue-800, 40%); 
                cursor: pointer;
            }    
        }
    }
}
@include media-breakpoint-down(lg) {
    #subnav {
        .h5 { font-size: 1.125rem; margin-bottom: 0; }
        div { font-size: .875rem; }
        .row > div { padding: .5rem 0; border-bottom: 1px solid var(--bs-body-bg);}
    }

}

.carousel-item img {
    border-radius: $border-radius;
}

.react-photo-album img {
    border-radius: $border-radius;
    background-color: rgba($gray-500, .25);
}

.yarl__container {
    background: rgba($black, .75);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}
.yarl__thumbnails_container {
    background: rgba($black, .95);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}
.yarl__slide_captions_container {
    background-color: rgba(0,0,0,.65);
    padding: .5rem;
}


.phases {
	text-align: center;
    ul {
        list-style: none;
        display: inline-table;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            div {
                display: block;
                text-align: center;
                padding: 0 10px 0 30px;
                position: relative;
                margin: 0 10px 0 0; 
                font-size: .9rem;
                height: 40px;
                line-height: 40px;            
                &:after {
                    content: "";  
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 20px solid #555;
                    position: absolute; right: -20px; top: 0;
                    z-index: 1;
                }
                &:before {
                    content: "";  
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 20px solid var(--bs-body-bg);
                    position: absolute; left: 0; top: 0;
                }
            }
            .bg-primary {
                &:after { border-left-color: $primary; }
            }
            .bg-success {
                &:after { border-left-color: $teal; }
            }
            .bg-info {
                &:after { border-left-color: $info; }
            }
            .bg-warning {
                &:after { border-left-color: $warning; }
            }
            .bg-orange {
                &:after { border-left-color: $orange; }
            }
            .bg-purple {
                &:after { border-left-color: $purple; }
            }
            .disabled {
                background: $gray-400 !important;
                color: $gray-200;
                &:after { border-left-color: $gray-400; }
            }
            &:first-child div {
                padding-left: 15px;
                border-top-left-radius: 10px; 
                border-bottom-left-radius: 10px;
                &:before {
                    display: none; 
                }
            }
            &:last-child div {
                padding-right: 15px;
                border-top-right-radius: 10px; 
                border-bottom-right-radius: 10px;
                &:after {
                    display: none; 
                }
            }
              
        }
    }
}
@include media-breakpoint-down(md) {
    .phases {
        ul {
            li {
                div {
                    padding: 0 5px 0 25px;
                    margin: 0 3px 0 0;             
                    font-size: .875rem;
                    i { display: none; }
                }
                &:first-child div {
                    padding-left: 10px;
                }
                &:last-child div {
                    padding-right: 10px;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .phases {
        ul {
            li {
                div {
                    i { display: inline; }
                    span { display: none; }
                }
            }
        }
    }
}
.yarl__slide_description .phases {
    ul {
        li {
            div {
                padding: 0 5px 0 25px;
                margin: 0 3px 0 0;             
                font-size: .875rem;
                i { display: inline; }
                // span { display: none; }
                &:before {
                    border-left-color: $black;
                }
            }
            &:first-child div {
                padding-left: 10px;
            }
            &:last-child div {
                padding-right: 10px;
            }
        }
    }
}