// bento
@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

#footer {
    font-size: .875rem;
    background: $red-700;
    color: $white;
    a:link, a:visited {
        color: $gray-100;
    }
    a:active, a:hover {
        color: $white;
    }

}
@include color-mode(dark) {
    #footer {
        background-color: rgba($gray-900, .65);
    }
}

