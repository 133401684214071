// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme. Use this as a starting point for your own custom color modes by creating a new theme-specific file like `_variables-dark.scss` and adding the variables you need.

//
// Global colors
//

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #ebeff2 !default;//#f8f9fa !default;
$gray-200: #dce3eb !default;//#ecf0f1 !default;//$gray-200: #e9ecef !default;
$gray-300: #bccad7 !default;//#dee2e6 !default;
$gray-400: #9bafc0 !default;//#ced4da !default;
$gray-500: #7a93a9 !default;//#b4bcc2 !default;//$gray-500: #adb5bd !default;
$gray-600: #51697d !default;//#95a5a6 !default;//$gray-600: #6c757d !default;
$gray-700: #365269 !default;//#7b8a8b !default;//$gray-700: #495057 !default;
$gray-800: #012543 !default;//#343a40 !default;
$gray-900: #001324 !default;//#212529 !default;
$black:    #00070d !default;//#000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$navy:    #004b88 !default; // new color
$blue:    #0076d6 !default;//$blue:    #0d6efd !default;
$indigo:  #5c10f2;//#6610f2 !default;
$purple:  #6f42c1 !default;//$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #ff513a !default;//$red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #00ef16 !default;//#198754 !default;
$teal:    #00d688 !default;//$teal:    #20c997 !default;
$cyan:    #00b5d6 !default;//#0dcaf0 !default;
$lime:    #89ff00 !default; // new color
$chartreuse:    #d4ed00 !default; // new color
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $navy !default;//$gray-600 !default;
$success:       $teal !default;//$success:       $green !default;
$info:          $chartreuse !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-400 !default;//#c7ba8f !default;//$light:         $gray-100 !default;
$dark:          $gray-800 !default;// $dark:          $gray-900 !default;
// scss-docs-end theme-color-variables


// scss-docs-start sass-dark-mode-vars
// scss-docs-start theme-text-dark-variables
$primary-text-emphasis-dark:        tint-color($primary, 40%) !default;
$secondary-text-emphasis-dark:      tint-color($secondary, 40%) !default;
$success-text-emphasis-dark:        tint-color($success, 40%) !default;
$info-text-emphasis-dark:           tint-color($info, 40%) !default;
$warning-text-emphasis-dark:        tint-color($warning, 40%) !default;
$danger-text-emphasis-dark:         tint-color($danger, 40%) !default;
$light-text-emphasis-dark:          tint-color($light, 40%) !default;//$gray-100 !default;
$dark-text-emphasis-dark:           $gray-300 !default;
// scss-docs-end theme-text-dark-variables

// scss-docs-start theme-bg-subtle-dark-variables
$primary-bg-subtle-dark:            saturate(shade-color($primary, 80%), 35%) !default;//shade-color($primary, 80%) !default;
$secondary-bg-subtle-dark:          saturate(shade-color($secondary, 80%), 35%) !default;//shade-color($secondary, 80%) !default;
$success-bg-subtle-dark:            saturate(shade-color($success, 80%), 35%) !default;//shade-color($success, 80%) !default;
$info-bg-subtle-dark:               saturate(shade-color($info, 80%), 35%) !default;//shade-color($info, 80%) !default;
$warning-bg-subtle-dark:            saturate(shade-color($warning, 80%), 35%) !default;//shade-color($warning, 80%) !default;
$danger-bg-subtle-dark:             saturate(shade-color($danger, 80%), 35%) !default;//shade-color($danger, 80%) !default;
$light-bg-subtle-dark:              saturate(shade-color($light, 80%), 35%) !default;//$gray-800 !default;
$dark-bg-subtle-dark:               mix($gray-800, $black) !default;
// scss-docs-end theme-bg-subtle-dark-variables

// scss-docs-start theme-border-subtle-dark-variables
$primary-border-subtle-dark:        shade-color($primary, 20%) !default;//shade-color($primary, 40%) !default;
$secondary-border-subtle-dark:      shade-color($secondary, 20%) !default;//shade-color($secondary, 40%) !default;
$success-border-subtle-dark:        shade-color($success, 20%) !default;//shade-color($success, 40%) !default;
$info-border-subtle-dark:           shade-color($info, 20%) !default;//shade-color($info, 40%) !default;
$warning-border-subtle-dark:        shade-color($warning, 20%) !default;//shade-color($warning, 40%) !default;
$danger-border-subtle-dark:         shade-color($danger, 20%) !default;//shade-color($danger, 40%) !default;
$light-border-subtle-dark:          shade-color($light, 20%) !default;//shade-color($light, 40%) !default;//$gray-700 !default;
$dark-border-subtle-dark:           $gray-700 !default;//$gray-800 !default;
// scss-docs-end theme-border-subtle-dark-variables

$body-color-dark:                   $gray-100 !default;
$body-bg-dark:                      $dark !default;//$gray-900 !default;
$body-secondary-color-dark:         $body-color !default;//rgba($body-color-dark, .75) !default;
$body-secondary-bg-dark:            #0b2e4b !default;//$gray-800 !default;
$body-tertiary-color-dark:          $body-color !default;//rgba($body-color-dark, .5) !default;
$body-tertiary-bg-dark:             #1d3d57 !default;//mix($gray-800, $gray-900, 50%) !default;
$body-emphasis-color-dark:          $white !default;
$border-color-dark:                 $gray-700 !default;
$border-color-translucent-dark:     rgba($white, .15) !default;
$headings-color-dark:               inherit !default;
$link-color-dark:                   $success !default;//$link-color-dark:                   tint-color($primary, 40%) !default;
$link-shade-percentage:             20% !default;
$link-hover-color-dark:             shift-color($link-color-dark, -$link-shade-percentage) !default;
$code-color:                        $pink !default;
$code-color-dark:                   tint-color($code-color, 40%) !default;
$mark-color-dark:                   $body-color !default;//$mark-color-dark:                   $body-color-dark !default;
$mark-bg-dark:                      $lime !default;//$mark-bg-dark:                      $yellow-800 !default;


//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark !default;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-switch-color-dark:            rgba($white, .25) !default;
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;

// scss-docs-start form-validation-colors-dark
$green-300: tint-color($green, 40%) !default;
$form-valid-color-dark:             $green-300 !default;
$form-valid-border-color-dark:      $green-300 !default;
$red-300: tint-color($red, 40%) !default;
$form-invalid-color-dark:           $red-300 !default;
$form-invalid-border-color-dark:    $red-300 !default;
// scss-docs-end form-validation-colors-dark


//
// Accordion
//

$accordion-icon-color-dark:         $primary-text-emphasis-dark !default;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark !default;

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end sass-dark-mode-vars
