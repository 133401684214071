// stat
@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

.stat {
    background: rgba($white, .45);
    border: 1px solid $white;
    border-top-color: rgba($gray-300, .25);
    border-left-color: rgba($gray-300, .25);
    border-radius: $border-radius;
    box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.1);
    .stat-value { 
        font-size: 2rem; 
        font-weight: bold; 
        line-height: 1;
        margin-bottom: .5rem;
        color: $maroon;
    }
}
.stat-lg {
    .stat {
        .stat-value { font-size: 4rem; }
    }
}
.stat-md {
    .stat {
        .stat-value { font-size: 3rem; }
    }
}
@include color-mode(dark) {
    .stat {
        background: rgba($gray-700, .25);
        border: 1px solid $gray-700;
        border-top-color: rgba($gray-600, .25);
        border-left-color: rgba($gray-600, .25);
        .stat-value { 
            color: $teal;
        }
    }
}

