@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

.studies-intro {
    position: relative;
    box-shadow: 0px -10px 50px rgba($purple, 0.2);
    padding: 3rem 1rem;
    
    .team-content-area:hover .member-thumb img, .team-content-area:focus .member-thumb img {
        -webkit-transform: rotate(25deg);
        -ms-transform: rotate(25deg);
        transform: rotate(25deg); 
    }
    h3 { color: $maroon; }
    small { color: $red-600; }
    .smaller { 
        font-size: .625rem;
        color: $gray-400;
    }
    .card { 
        position: relative;
        overflow: hidden;
        max-width: 16rem;
        border: 1px solid $white;
        border-top-color: rgba($gray-300, .5);
        border-left-color: rgba($gray-300, .5);
        .card-body {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                height: 150%;
                background: var(--bs-body-bg) url(../../public/img/backgrounds/network-bg1.png) center bottom repeat-x;
                z-index: -1;
                -webkit-transform: scale(-1);
                transform: scale(-1);
            }
            img { background-color: $red-800; }
        }
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: url(../../public/img/backgrounds/network-bg1.png) left bottom repeat-x;
        z-index: -2;
    }
}
@include color-mode(dark) {
    .studies-intro {
        box-shadow: 0px -10px 50px rgba($black, 0.2);
        h3 { color: $lime; }
        small { color: $blue-400; }
        .card {
            border: 1px solid $gray-700;
            border-top-color: rgba($gray-600, .25);
            border-left-color: rgba($gray-600, .25);                
            .card-body {
                &:before {
                    background: $dark url(../../public/img/backgrounds/network-bg2.png) center bottom repeat-x; 
                }
                img { background-color: $dark; }
            }        
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background: url(../../public/img/backgrounds/network-bg2.png) left bottom repeat-x;
            z-index: -2;
        }
    }
}
@include media-breakpoint-down(md) {
    .studies-intro {
        &:before {
            background: url(../../public/img/backgrounds/network-bg1b.png) right bottom repeat-y;
        }
    }
    @include color-mode(dark) {        
        .studies-intro {
            &:before {
                background: url(../../public/img/backgrounds/network-bg2b.png) right bottom repeat-y;
            }
        }
    }
}

.cs {
    padding: 3rem 1rem;
    .lead { color: $coral; }
    h3 { color: $maroon; }
}
@include color-mode(dark) {
    .cs {
        .lead { color: $blue-300; }
        h3 { color: $lime; }
    }
}

.kudo-strip {
    padding: 1.5rem 1rem;
    background: $coral;
    p { 
        color: $white; 
        text-shadow: 1px 1px 3px rgba($maroon, .5), 1px 1px 2px rgba($maroon, 1);
    }
    div { color: $black; }
    
    .lquo, .rquo {
        position: relative;
        display: inline-block;
        &.mirror-icon {
            -moz-transform: scale(-1, -1);
            -o-transform: scale(-1, -1);
            -webkit-transform: scale(-1, -1);
            transform: scale(-1, -1);
        }
    }
    .lquo {
        top: -.25rem;
    }
    .rquo {
        top: -.25rem;
    }
}
@include color-mode(dark) {
    .kudo-strip {
        background-color: $blue-800;
        background: $blue-800;
        p {  color: $teal; text-shadow: 1px 1px 3px rgba($black, .5), 1px 1px 2px rgba($black, 1); }
        div { color: $white; }
    }
}

