@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";



$numberOfSlidesFavs: 20;
$animationSpeedFavs: $numberOfSlidesFavs * 2s;
$numberOfSlidesMore: 75;
$animationSpeedMore: $numberOfSlidesMore * 2s;
$numberOfSlidesBrands: 164;
$animationSpeedBrands: $numberOfSlidesBrands * 2s;
$numberOfSlidesBmoc: 20;
$animationSpeedBmoc: $numberOfSlidesBmoc * 2s;
$slideWidth: 90px;
$slideHeight: 50px;

// Animation
@keyframes scrollFavs {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-1 * $slideWidth * $numberOfSlidesFavs))}
}
@keyframes scrollMore {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-1 * $slideWidth * $numberOfSlidesMore))}
}
@keyframes scrollBrands {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-1 * $slideWidth * $numberOfSlidesBrands))}
}
@keyframes scrollBmoc {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-1 * $slideWidth * $numberOfSlidesBmoc))}
}


// Styling
.slider {
	height: $slideHeight;
	margin: 2rem auto;
	overflow: hidden;
	position: relative;
	width: 100%;
	
	&::before,
	&::after {
		position: absolute;
		top: 0;
		content: "";
		height: 100%;
		width: 6rem;
		z-index: 2;
	}	
	&::after {
		right: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--bs-body-bg));
	}
	&::before {
		left: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--bs-body-bg));
	}
	
	.slide-track {
		animation: scrollMore $animationSpeedMore linear infinite;
		display: flex;
		width: calc($slideWidth * $numberOfSlidesMore * 2);
        &:hover { animation-play-state: paused; }
	}
	
	.slide {
        display: inline-block;
        text-align: center;
		height: $slideHeight;
		width: $slideWidth !important;
        img, svg {
            height: $slideHeight;
            margin: 0 20px;
        }
	}

    &.favs {
        .slide-track {
            animation: scrollFavs $animationSpeedFavs linear infinite;
            width: calc($slideWidth * $numberOfSlidesFavs * 2);
        }
        
        .slide {
            &:not(.fav) {
              display: none !important;
              width: 0;
              height: 0;
              top: -999px;
            }
        }

    }

    &.brands {
        .slide-track {
            animation: scrollBrands $animationSpeedBrands linear infinite;
            width: calc($slideWidth * $numberOfSlidesBrands * 2);
        }
    }

    
    &.bmoc {
        .slide-track {
            animation: scrollBmoc $animationSpeedBmoc linear infinite;
            width: calc($slideWidth * $numberOfSlidesBmoc * 2);
        }
        
        .slide {
            &:not(.bmoc) {
              display: none !important;
              width: 0;
              height: 0;
              top: -999px;
            }
        }

    }
}