@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

.swiper {
    padding: 2.5rem;
}
.swiper-button-prev, .swiper-button-next {
    background: $coral;
    height: 100%;
    top: 0;
    margin-top: 0;
    width: 2.5rem;
    &::after {
        color: rgba($white, .75);
    }    
    &:hover::after {
        color: $white;
    }
}
@include color-mode(dark) {
    .swiper-button-prev, .swiper-button-next {
        background: $blue-800;
    }
}
.swiper-button-prev {
    left: -.5rem;
}
.swiper-button-next {
    right: -.5rem;
}
.swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
}
.swiper-pagination-bullet-active {
    background: $white;
}
.swiper-pagination {
    bottom: -.5rem !important;
}

.kudo {
    .card {
        clear: both;
        box-shadow: none;
        background: rgba($white, .35);
        border: 0;
        .card-body {
            position: relative;
            padding: 1.5rem;
            .lquo, .rquo {
                position: absolute;
                display: block;
                width: 100%;
                font-size: 3rem;
                color: rgba($white, .75);
                &.mirror-icon {
                    top: .25rem;
                    -moz-transform: scale(-1, -1);
                    -o-transform: scale(-1, -1);
                    -webkit-transform: scale(-1, -1);
                    transform: scale(-1, -1);
                }
            }
            .lquo {
                top: -2rem;
                left: -.75rem;
            }
            .rquo {
                bottom: -2rem;
                right: -.75rem;
            }
            
            .quote {
                font-style: italic;
                font-size: .875rem;
            }
            span {
                color: #d12f1a;
                font-weight: 500;
            }
        }
    }
    .qimage {
        width: 60px;
        height: 60px;
        float: left;
        margin: .5rem .5rem 1rem .5rem;
    }
    .qname {
        font-weight: 700;
        margin: .75rem 1.25rem 0 1.25rem;
    }
    .qtitle {
        font-size: .75rem;
        margin: 0 1.25rem;
    }
}
@include color-mode(dark) {
    .kudo {
        .card {
            background: rgba($white, .15);
            .card-body {
                span {
                    color: $lime;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .swiper {
        padding: 2.5rem .25rem;
    }
    .swiper-button-prev, .swiper-button-next {
        display: none;
    }
}