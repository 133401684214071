// bento
@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

$unit-1: 8rem;
$unit-2: $unit-1 * 2;
$unit-3: $unit-1 * 3;
.h-33 {
    height: 33%;
}
.h-34 {
    height: 34%;
}
.h-66 {
    height: calc(66% + #{$grid-gutter-width});
}
.bento {
    min-height: $unit-3;
    .row {
        margin-bottom: $grid-gutter-width;
    }
    .cube {
        &.cube-1x1 {
            width: $unit-1;
            height: $unit-1;
        }
        &.cube-2x1 {
            width: $unit-2;
            height: $unit-1;
        }
        &.cube-1x2 {
            width: $unit-1;
            height: $unit-1;
        }
        &.cube-2x2 {
            width: $unit-2;
            height: $unit-1;
        }
    }
}
@include media-breakpoint-down(sm) {
    .h-33, .h-34, .h-66 {
        height: auto;
        min-height: $unit-1;
    }
}
// @include color-mode(dark) {
// }

