@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";


// allow the hero to go behind the header while making 'About' highlightable
.studies-container {
    margin-top: -110px;
}

// for light mode the background is a daytime pic
// applied to the hero container
.studies-container {
    background: url(../../public/img/heroes/bg-dawn.jpg) no-repeat;
    background-size: cover;
}
// for dark mode the background is a nighttime pic
@include color-mode(dark) {
    .studies-container {
        background: url(../../public/img/heroes/bg-aurora.jpg) no-repeat;
        background-size: cover;
    }
}


// for light mode there's a daytime photo for the hero
.studies-image {
    background-image: url(../../public/img/heroes/team-day.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    top:0;
    left:0;
    bottom:-5%;
    width: 50%;
}
// for dark mode there's a nighttime photo for the hero, with a gradient overlaid on it and the background
@include color-mode(dark) {
    .studies-image {
        background-image: url(../../public/img/heroes/team-night.png);
    }
}
@include media-breakpoint-up(xl) {// 1200px and up
    .studies-image {
        bottom:-15%;
    }
}
@media (min-width: 1500px) {// 1500px and up
    .studies-image {
        background-position: right bottom;
        left: -5rem;
    }
}
.bg-sparkle1 {
    background-image: url(../../public/img/heroes/sparkles1.png);
}
.bg-sparkle2 {
    background-image: url(../../public/img/heroes/sparkles2.png);
}

// hero content colors for light mode
// positioning the text inside the hero
.studies-content {
    top:0;
    left: 50%;
    bottom:0;
    width: 50%;
    max-width: 45rem;
    > div { padding-right: 2rem;}
    .lead {
        white-space: nowrap;
        span.hero-name { color: $red-600; }
        span.hero-title { color: $danger; }
        span { //icon
            svg {
                margin-top: -4px;
                width: 1rem;
                height: 1rem;                
                .atom {
                    fill: $orange;
                }
                .heart {
                    fill: $purple-300;
                }
            }
        }
    }
    h1 { //rainbow text
        font-size: 2.25rem;
        line-height: 1.05;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to right, #ff5e69 20%, #ff8a56 50%, #ffa84b 80%, #ffd54b 100%);
        -webkit-background-clip: text;
        background-clip: text;
        em { margin-right: .25rem; }
    }
    p { color: var(--bs-body-color); } //black text
}
// hero content colors for dark mode
@include color-mode(dark) {
    .studies-content {
        .lead {
            span.hero-name { color: $lime; }
            span.hero-title { color: $teal; }
            span { //icon
                svg {             
                    .atom {
                        fill: $gray-200;
                    }
                    .heart {
                        fill: $lime;
                    }
                }
            }
        }
        h1 {//rainbow text
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to right, $lime 10%, $teal 30%, #00ffea 60%, #f188ff 100%);
            -webkit-background-clip: text;
            background-clip: text;
        }
        p { color: var(--bs-body-color); }//white text
    }
}
@include media-breakpoint-up(lg) {// 992px and up
    .studies-content {
        left: 48%;
        width: 52%;
    }
}



// Because we want to animate the opacity of the paragraph
// separately from the rest of the text, but we want to keep
// all of the text lined up during the parallax animation,
// we are adding the text twice. It will be 'there' but hidden so
// you don't see it twice, but it will physically be used to
// retain the spacing needed for the text that is not hidden.
.studies-content {
    &.studies-content-1 {
        //text-shadow: 1px 2px 3px rgba(0,0,0,.5);
        // in this layer, the lead and h1 are visible, the p is hidden
        p { color: transparent; }
    }
    &.studies-content-2 {
        // in this layer, the lead and h1 are hidden, the p is visible
        .lead { color: transparent; }
        h1 { color: transparent; }        
    }
}


// the hero container wraps around the parallax items
// for large screens, have the hero take up the whole screen
.studies-container {
    height: 56vh;
    min-height: 500px;
    max-height: 520px;
}
@include media-breakpoint-only(md) {
    .studies-image {
        bottom: auto;
        top: -1rem;
        height: 100%;
    }
}




@include media-breakpoint-down(xxl) {// 1400px and down
    .studies-content {
        h1 {
            font-size: 1.75rem;
        }
    }
}

@include media-breakpoint-down(lg) {// 992px and down
    .studies-content {
        p { font-size: .9rem; }
    }
}

// On very small screens we are hiding all of the text
// except for the lead in the hero area, and showing the rest
// of the text in a div stacked below the hero.
.studies-stack {
    display: none; // hide the text in the bottom area
}
@include media-breakpoint-down(md) { // 768px and down
    .studies-container {
        height:  320px;
        min-height: 320px;
    }
    .studies-content {
        text-align: center;
        padding: 0 1rem;
        &.studies-content-1, &.studies-content-2 {
            .studies-blurb { display: none; } // hide the text in the top area
        }
    }
    .studies-image {
        top: 2rem;
    }
    .studies-stack {
        display: block; // show the text in the bottom area
    }
    .studies-content {
        left: 47%;
        .lead {
            white-space: normal;
            span.hero-name { color: $red-600; }
            span.hero-title { color: $danger; }
            span { //icon
                svg { display: none;}
            }
        }
    }
}
@include media-breakpoint-down(sm) { // 576px and down
    .studies-content .lead {
        font-size: 1rem;
    }
    .studies-content {
        left: 49%;
    }
    .studies-image {
        width: 60%;
    }
}
@media (max-width: 480px) {
    .studies-container {
        height:  270px;
        min-height: 270px;
    }
    // .studies-content {
    //     left:45%;
    // }
    // .studies-image {
    //     right:48%;
    // }
}

// the stacked content on smaller screens
.studies-stack {
    position: relative;
    padding: 2rem 1rem 3rem 1rem;    
	background: linear-gradient(-45deg, #ec3820, #ff5233, #ff3683, #b32252, #6f42c1, #b242c1);
	background-size: 400% 400%;
	animation: gradient 25s ease infinite;
    box-shadow: 0px -10px 20px rgba($danger, 0.35);  
    h1, p { color: $white; }    
    h1 { font-size: 1.5rem; }
    h1 em { color: $red-200; margin-right: .25rem; }
}
@include color-mode(dark) {
    .studies-stack {
        background: linear-gradient(-45deg, #003968, #1f3133, #121931, #2b1844, #1b5570, #0d2049);
        background-size: 400% 400%;
        animation: gradient 25s ease infinite;
        box-shadow: 0px -10px 20px rgba($black, 0.35);  
        h1 { color: $white; }
        h1 em { color: $teal; }
    }
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
