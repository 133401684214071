// parallax hero section on home page
@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";


.skills-container {
   min-height: 520px;
}
.skills-image {
    position: absolute;
    top:0;
    bottom: 0;
    width: 100%;
    background-image: url(../../public/img/backgrounds/skillcloud.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    opacity: .1;
}
.section-cards {
    height: 100%;
    padding: 4rem 1rem;
    .skills-card {
        border: 1px solid $white;
        border-top-color: rgba($gray-300, .25);
        border-left-color: rgba($gray-300, .25);
        background-color: rgba(#ecebe6, .95); 
        box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.2);
        h2 { color: $maroon; }
        .card-img-top { 
            background-color: rgba($white, .35); 
            border-bottom: 1px solid rgba($white, .5); 
            border-top: 1px solid rgba($white, .5); 
        }
    }
}
@include color-mode(dark) {
    .skills-image {
        opacity: .05;
    }
    .section-cards {
        height: 100%;
        padding: 4rem 1rem;
        .skills-card {
            border: 1px solid $gray-700;
            border-top-color: rgba($gray-600, .45);
            border-left-color: rgba($gray-600, .45);
            background-color: rgba($gray-900, .65);    
            h2 { color: $lime; }     
            .card-img-top { 
                background-color: rgba($gray-800, .65); 
                border-bottom: 1px solid rgba($gray-500, .5); 
                border-top: 1px solid rgba($gray-500, .5); 
            }   
        }
    }
}
