@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";

.opath {
    fill: #000;
}

.opath, .lpath, .atom {
    stroke-width: 0px;
}

.heart {
    fill: #e21c42;
}

.atom {
    fill: #7a93a9;
}

.lpath {
    fill: none;
    stroke: rgba($gray-500, .35);
    stroke-miterlimit: 10;
    stroke-width: 2px;
}
.leadpath { 
    position: relative;
    background: $white; 
    z-index: 2;
    margin-bottom: -5px;
}
@include color-mode(dark) {
    .heart {
        fill: $lime;
    }
    .leadpath { 
        background: $dark; 
    }
}