// parallax hero section on home page
@import "~bootstrap/scss/functions";
@import "variables";
@import "variables-dark"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";
@import "~bootstrap/scss/mixins/color-scheme";


// allow the hero to go behind the header while making 'Home' highlightable
#who {
    margin-top: 55px;
    height: 1px;
}
.hero-container {
    margin-top: -110px;
}

// for light mode the background is a daytime pic
// applied to the hero container
.hero-container {
    background: url(../../public/img/heroes/bg-dawn.jpg) no-repeat;
    background-size: cover;
}
// for dark mode the background is a nighttime pic
@include color-mode(dark) {
    .hero-container {
        background: url(../../public/img/heroes/bg-aurora.jpg) no-repeat;
        background-size: cover;
    }
}


// for light mode there's a daytime photo for the hero
.hero-image {
    background-image: url(../../public/img/heroes/foreground-lm2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
}
// there's no overlay gradient for light mode
// for dark mode there's a nighttime photo for the hero, with a gradient overlaid on it and the background
@include color-mode(dark) {
    .hero-image {
        background-image: url(../../public/img/heroes/foreground-dm2.png);
    }
    .hero-overlay {
        display: block;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;        
        background:linear-gradient(to bottom,rgba($gray-900, 0),rgba($gray-900, 0),rgba($gray-900, .9));
        opacity: .25;
    }
}

.bg-sparkle1 {
    background-image: url(../../public/img/heroes/sparkles1.png);
}
.bg-sparkle2 {
    background-image: url(../../public/img/heroes/sparkles2.png);
}
@keyframes flickerAnimation {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }
  .animate-flicker1 {
    opacity: 0;
     -webkit-animation: flickerAnimation 2s 3;
     -moz-animation: flickerAnimation 2s 3;
     -o-animation: flickerAnimation 2s 3;
      animation: flickerAnimation 2s 3;
  }
  .animate-flicker2 {
    opacity: 0;
     -webkit-animation: flickerAnimation 2s 1s 3;
     -moz-animation: flickerAnimation 2s 1s 3;
     -o-animation: flickerAnimation 2s 1s 3;
      animation: flickerAnimation 2s 1s 3;
  }


// hero content colors for light mode
// positioning the text inside the hero
.hero-content {
    max-width: 38rem;
    top:0;
    right:5%;
    bottom:0;
    .lead {
        white-space: nowrap;
        span.hero-name { color: $red-600; }
        span.hero-title { color: $danger; }
        span { //icon
            svg {
                margin-top: -4px;
                width: 1rem;
                height: 1rem;                
                .atom {
                    fill: $orange;
                }
                .heart {
                    fill: $purple-300;
                }
            }
        }
    }
    h1 { //rainbow text
        font-size: 2.5rem;
        line-height: 1.05;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to right, #ff5e69 20%, #ff8a56 50%, #ffa84b 80%, #ffd54b 100%);
        -webkit-background-clip: text;
        background-clip: text;
    }
    p { color: var(--bs-body-color); } //black text
}
// hero content colors for dark mode
@include color-mode(dark) {
    .hero-content {
        .lead {
            span.hero-name { color: $lime; }
            span.hero-title { color: $teal; }
            span { //icon
                svg {             
                    .atom {
                        fill: $gray-200;
                    }
                    .heart {
                        fill: $lime;
                    }
                }
            }
        }
        h1 {//rainbow text
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to right, $lime 10%, $teal 30%, #00ffea 60%, #f188ff 100%);
            -webkit-background-clip: text;
            background-clip: text;
        }
        p { color: var(--bs-body-color); }//white text
    }
}


// Because we want to animate the opacity of the paragraph
// separately from the rest of the text, but we want to keep
// all of the text lined up during the parallax animation,
// we are adding the text twice. It will be 'there' but hidden so
// you don't see it twice, but it will physically be used to
// retain the spacing needed for the text that is not hidden.
.hero-content {
    &.hero-content-1 {
        //text-shadow: 1px 2px 3px rgba(0,0,0,.5);
        // in this layer, the lead and h1 are visible, the p is hidden
        p { color: transparent; }
    }
    &.hero-content-2 {
        // in this layer, the lead and h1 are hidden, the p is visible
        .lead { color: transparent; }
        h1 { color: transparent; }        
    }
}


// the hero container wraps around the parallax items
// for large screens, have the hero take up the whole screen
.hero-container {
    height: 56vh;
    min-height: 480px;
}
// on large screens the hero image and the text take up 50/50
.hero-content {
    left:52%;
}
.hero-image {
    right:51%;
}

@media (orientation: portrait) {
    @include media-breakpoint-up(xl) {// 1200px and up
        .hero-container {
            max-height: 800px;
        }
    }
    @include media-breakpoint-between(md,xl) {// 768px - 1199px
        .hero-container {
            max-height: 480px;
        }
    }
}
@media (orientation: landscape) {
    @include media-breakpoint-up(xl) {// 1200px and up
        .hero-container {
            max-height: 1200px;
        }
    }
}

@include media-breakpoint-down(xxl) {// 1400px and down
    .hero-content {
        h1 {
            font-size: 2rem;
        }
    }
}

@include media-breakpoint-down(lg) {// 992px and down
    .hero-content {
        p { font-size: .9rem; }
    }
}

// On very small screens we are hiding all of the text
// except for the lead in the hero area, and showing the rest
// of the text in a div stacked below the hero.
.hero-stack {
    display: none; // hide the text in the bottom area
}
@include media-breakpoint-down(md) {
    .hero-container {
        height:  320px;
        min-height: 320px;
    }
    .hero-content {
        left:56%;
        right: 0;
        text-align: center;
        padding: 0 1rem;
        &.hero-content-1, &.hero-content-2 {
            .hero-blurb { display: none; } // hide the text in the top area
        }
    }
    .hero-image {
        right:43%;
        margin-top: 70px;
    }
    .hero-stack {
        display: block; // show the text in the bottom area
    }
    .hero-content {
        .lead {
            white-space: normal;
            span.hero-name { color: $red-600; }
            span.hero-title { color: $danger; }
            span { //icon
                svg { display: none;}
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .hero-content .lead {
        font-size: 1rem;
    }
    .hero-content {
        left:53%;
    }
    .hero-image {
        right:43%;
    }
}
@media (max-width: 480px) {
    .hero-container {
        height:  240px;
        min-height: 240px;
    }
    .hero-content {
        left:45%;
    }
    .hero-image {
        right:48%;
    }
}

// the stacked content on smaller screens
.hero-stack, .gradient-bg {
    position: relative;
    padding: 2rem 1rem 3rem 1rem;    
	background: linear-gradient(-45deg, #ec3820, #ff5233, #ff3683, #b32252, #6f42c1, #b242c1);
	background-size: 400% 400%;
	animation: gradient 25s ease infinite;
    box-shadow: 0px -10px 20px rgba($danger, 0.35);  
    h1, p { color: $white; }    
    h1 { font-size: 2rem; }
    h1 em { color: $red-200; }
}
@include color-mode(dark) {
    .hero-stack, .gradient-bg {
        background: linear-gradient(-45deg, #003968, #1f3133, #121931, #2b1844, #1b5570, #0d2049);
        background-size: 400% 400%;
        animation: gradient 25s ease infinite;
        box-shadow: 0px -10px 20px rgba($black, 0.35);  
        h1 { color: $white; }
        h1 em { color: $teal; }
    }
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
